import Highcharts from 'highcharts/highcharts.src'
import HighchartsBoost from 'highcharts/modules/boost'
import merge from 'lodash.merge'

HighchartsBoost(Highcharts)
window.Highcharts = Highcharts

/**
 * @typedef {Object} ChartDataRecord
 * @property {string} measured_at
 * @property {number} power
 * @property {number} humidity
 * @property {number} R1
 * @property {number} R2
 * @property {number} R3
 * @property {number} R4
 * @property {number} temperature
 * @property {number} cd1
 * @property {number} cd2
 * @property {number} cd3
 * @property {number} ema_smooth_cd1
 * @property {number} ema_smooth_cd2
 * @property {number} ema_smooth_cd3
 * @property {number|null} cr1
 * @property {number|null} cr2
 * @property {number|null} cr3
 * @property {boolean} within_bounds
 */

/**
 * @async
 * @param {string} elementId
 * @returns {Promise<[HTMLDivElement, ChartDataRecord[]]>}
 */
async function replaceScript (elementId) {
  /** @type {HTMLScriptElement} */
  const script = document.getElementById(elementId)
  if (!script) return []

  /**
   * @param {ChartDataRecord[]} data
   * @returns {[HTMLDivElement, ChartDataRecord[]]}
   */
  function placeChart (data) {
    const chartEl = document.createElement('div')
    chartEl.id = script.id
    chartEl.className = script.className
    chartEl.classList.add('chart-loaded')
    script.parentElement.insertBefore(chartEl, script)
    script.remove()

    return [chartEl, data]
  }

  if (script.textContent.length > 0) {
    const data = JSON.parse(script.textContent)
    return placeChart(data)
  } else if (script.src.length > 0) {
    const response = await fetch(script.src, {
      headers: { 'Content-Type': 'application/json' }
    })
    const data = await response.json()
    return placeChart(data)
  }
}

/**
 * @param {(string|null)} value
 * @returns {number}
 */
function toNumber (value) {
  return parseFloat(value)
}

/**
 * @this {Highcharts.Axis}
 * @returns {number[]}
 */
function tickPositioner () {
  const min = Math.floor(this.dataMin || this.min)
  const max = Math.ceil(this.dataMax || this.max)
  let tick = Math.floor(min)
  const positions = []
  const idealIncrement = Math.ceil((max - min) / 6)
  const increments = [0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500]
  const increment = increments.find(value => value >= idealIncrement)

  for (; (tick - increment) <= max && (this.dataMax === null ? tick <= this.max : true); tick += increment) {
    positions.push(tick)
  }

  return positions
}

/**
 * @param {string} name
 * @param {ChartDataRecord[]} data
 * @param {function(ChartDataRecord): (number|string)} fun
 * @param {Object} [extra=null]
 * @returns {Object}
 */
function createDataSeries (name, data, fun, extra = null) {
  return merge({
    name,
    type: 'line',
    data: data.map((record, index) => [Date.parse(record.measured_at), toNumber(fun(record)), index]),
    lineWidth: 1,
    marker: {
      symbol: 'circle',
      enabled: undefined
    },
    keys: ['x', 'y', 'custom.id'],
    tooltip: {
      valueDecimals: 2,
      headerFormat: '<span style="font-size: 10px">{point.key} (Index {point.point.custom.id})</span><br/>',
      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>\n'
    },
    states: {
      hover: {
        lineWidth: 0,
        lineWidthPlus: 0
      }
    }
  }, extra || {})
}

/**
 * @param {Object} e
 * @param {number} e.min
 * @param {number} e.max
 * @param {string} e.trigger
 * @this {Highcharts.Axis}
 */
function syncExtremes (e) {
  const thisChart = this.chart
  if (e.trigger !== 'syncExtremes') {
    Highcharts.each(Highcharts.charts, function (chart) {
      if (chart !== thisChart && chart.xAxis[0].setExtremes) {
        chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, { trigger: 'syncExtremes' })
      }
    })
  }
}

/**
 * @returns {undefined}
 */
Highcharts.Pointer.prototype.reset = function () {
  return undefined
}

/**
 * @param {Event} event
 */
Highcharts.Point.prototype.highlight = function (event) {
  event = this.series.chart.pointer.normalize(event)
  this.onMouseOver()
  this.series.chart.tooltip.refresh(this)
  this.series.chart.xAxis[0].drawCrosshair(event, this)
}

/**
 * @async
 * @returns {Promise<void>}
 */
async function initializeHighcharts () {
  const [el, data] = await replaceScript('highcharts')
  if (!el) return

  const envChart = document.createElement('div')
  const corrosionChart = document.createElement('div')

  envChart.classList.add('highcharts-light')
  corrosionChart.classList.add('highcharts-light')

  el.appendChild(corrosionChart)
  el.appendChild(envChart)

  // for (const eventType of ['mousemove', 'touchmove', 'touchstart']) {
  //   el.addEventListener(eventType, function (e) {
  //     let chart, i, event
  //     for (i = 0; i < Highcharts.charts.length; i++) {
  //       chart = Highcharts.charts[i]
  //       event = chart.pointer.normalize(e)
  //       for (const series of chart.series) {
  //         const point = series.searchPoint(event, true)?.highlight(e)
  //         if (point) { /* empty */ }
  //       }
  //     }
  //   })
  // }

  const envSeries = []
  const corrosionSeries = []
  const scatterOptions = { type: 'line', marker: { radius: 2 }, tooltip: { valueSuffix: ' µm' } }
  const lineOptions = { type: 'line', marker: { enabled: false }, tooltip: { valueSuffix: ' µm' } }

  /**
   * @param {*} num
   * @returns {(number|null)}
   */
  const notNegative = (num) =>
    typeof num === 'number' ? Math.max(0, num) : null

  envSeries.push(createDataSeries('Temperature', data, (record) => record.temperature, merge({}, scatterOptions, {
    yAxis: 0,
    tooltip: { valueSuffix: ' °C', valueDecimals: 1 }
  })))
  envSeries.push(createDataSeries('Humidity', data, (record) => record.humidity, merge({}, scatterOptions, {
    yAxis: 1,
    tooltip: { valueSuffix: ' %', valueDecimals: 1 }
  })))

  corrosionSeries.push(createDataSeries('CD1', data, (record) =>
    notNegative(record.ema_smooth_cd1), merge({}, lineOptions, {
    visible: false,
    marker: { symbol: 'circle' }
  })))
  corrosionSeries.push(createDataSeries('CD1 (raw)', data, (record) =>
    notNegative(record.cd1), merge({}, scatterOptions, {
    visible: false,
    marker: { symbol: 'circle' },
    linkedTo: ':previous',
    opacity: 0.5,
    zIndex: 0
  })))

  corrosionSeries.push(createDataSeries('CD2', data, (record) =>
    notNegative(record.ema_smooth_cd2), merge({}, lineOptions, {
    visible: false,
    marker: { symbol: 'square' }
  })))
  corrosionSeries.push(createDataSeries('CD2 (raw)', data, (record) =>
    notNegative(record.cd2), merge({}, scatterOptions, {
    visible: false,
    marker: { symbol: 'square' },
    linkedTo: ':previous',
    opacity: 0.5,
    zIndex: 0
  })))

  corrosionSeries.push(createDataSeries('CD3', data, (record) =>
    notNegative(record.ema_smooth_cd3), merge({}, lineOptions, {
    visible: false,
    marker: { symbol: 'triangle' }
  })))
  corrosionSeries.push(createDataSeries('CD3 (raw)', data, (record) =>
    notNegative(record.cd3), merge({}, scatterOptions, {
    visible: false,
    marker: { symbol: 'triangle' },
    linkedTo: ':previous',
    opacity: 0.5,
    zIndex: 0
  })))

  corrosionSeries.push(createDataSeries('CR1', data, (record) =>
    notNegative(record.cr1), merge({}, lineOptions, {
    type: 'areaspline',
    stacking: 'normal',
    yAxis: 1,
    tooltip: { valueSuffix: '  µm/year' },
    marker: { symbol: 'circle' }
  })))
  corrosionSeries.push(createDataSeries('CR2', data, (record) =>
    notNegative(record.cr2), merge({}, lineOptions, {
    type: 'areaspline',
    stacking: 'normal',
    yAxis: 1,
    tooltip: { valueSuffix: '  µm/year' },
    marker: { symbol: 'square' }
  })))
  corrosionSeries.push(createDataSeries('CR3', data, (record) =>
    notNegative(record.cr3), merge({}, lineOptions, {
    type: 'areaspline',
    stacking: 'normal',
    yAxis: 1,
    tooltip: { valueSuffix: '  µm/year' },
    marker: { symbol: 'triangle' }
  })))

  const invalidBands = []
  let band = null
  for (const record of data) {
    if (!record.within_bounds) {
      if (!band) {
        band = {
          from: Date.parse(record.measured_at),
          color: 'rgba(255,0,0,0.1)',
          borderColor: 'rgba(255,0,0,0.5)',
          label: {
            text: 'Out of sensor bounds',
            align: 'left',
            verticalAlign: 'middle',
            rotation: -90,
            x: +20,
            y: +60,
            useHTML: true,
          }
        }
      }
      band.to = Date.parse(record.measured_at)
    }
    if (band && record.within_bounds) {
      invalidBands.push(band)
      band = null
    }
  }

  if (band) {
    invalidBands.push(band)
    band = null
  }

  Highcharts.chart({
    chart: {
      styledMode: true,
      renderTo: corrosionChart,
      spacingTop: 40,
      zooming: {
        enabled: true,
        type: 'x',
        resetButton: {
          relativeTo: 'spacingBox',
          position: { y: 0, x: -80 }
        }
      }
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      followPointer: true
    },
    title: { text: ' ' },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      minRange: 3600000 * 3,
      events: { setExtremes: syncExtremes },
      plotBands: invalidBands
    },
    plotOptions: {
      column: { pointPadding: 0, borderWidth: 0, groupPadding: 0, shadow: false },
      scatter: { lineWidth: 0 },
      line: { lineWidth: 0.5 }
    },
    yAxis: [
      {
        title: { text: 'Corrosion depth (µm)', style: { color: Highcharts.getOptions().colors[0] } },
        labels: { format: '{value}', style: { color: Highcharts.getOptions().colors[0] } }
      },
      {
        title: { text: 'Corrosion rate (µm/year)', style: { color: Highcharts.getOptions().colors[1] } },
        labels: { format: '{value}', style: { color: Highcharts.getOptions().colors[1] } },
        opposite: true
      }
    ],
    series: corrosionSeries
  })

  Highcharts.chart({
    chart: {
      styledMode: true,
      spacingTop: 40,
      renderTo: envChart,
      zoomType: 'x',
      resetZoomButton: {
        relativeTo: 'spacingBox',
        position: { y: 0, x: -90 }
      }
    },
    tooltip: {
      shared: true,
      split: true,
      crosshairs: true,
      followPointer: true
    },
    title: { text: ' ' },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      minRange: 3600000 * 3
    },
    yAxis: [
      {
        labels: { format: '{value} °C', style: { color: Highcharts.getOptions().colors[2] } },
        title: { text: 'Temperature', style: { color: Highcharts.getOptions().colors[2] } },
        tickPositioner,
        tickAmount: 6,
        alignTicks: true
      },
      {
        labels: { format: '{value} %', style: { color: Highcharts.getOptions().colors[3] } },
        title: { text: 'Humidity', style: { color: Highcharts.getOptions().colors[3] } },
        min: 0,
        max: 100,
        opposite: true,
        tickAmount: 6,
        gridZIndex: -1
      }
    ],
    series: envSeries
  }).redraw()
}

document.addEventListener('turbo:load', initializeHighcharts)
